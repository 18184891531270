import "./styles.css";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import {
  FaFacebookSquare,
  FaYoutubeSquare,
  FaInstagramSquare,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { useForm } from "@formspree/react";
import "./PreviewPage.scss"; // Import your SCSS file for styling
import Typography from "@mui/material/Typography";
import VideoOverlay from "./VideoOverlay";

import Footer from "./Footer";

const footerLogo = require("./script/test-img/logo.png");

const PortfolioList = [
  {
    images: "10",
    category: "BRAND",
    title: "redlightcam",
    serve: "/portfolio/redlightcam",
    platform: "",
  },
  {
    images: "4",
    category: "MOBILE GAME",
    title: "BL1NK",
    serve: "/portfolio/bl1nk",
    platform: "",
  },
  {
    images: "1",
    category: "MOBILE GAME",
    title: "Keep On Trucking",
    serve: "/portfolio/keep-on-trucking",
    platform: "",
  },
  {
    images: "8",
    category: "MOBILE GAME",
    title: "Stack 8",
    serve: "/portfolio/stack-8",
    platform: "",
  },
];
export default function AppTest() {
  const { pathname, hash, key } = useLocation();
  const [query] = useState("");
  useEffect(() => {
    if (hash === "") {
      window.scrollTo(0, 0);
      document.getElementById("homeMenuText").style.borderBottom =
        "solid 2px red";
    } else if (hash === "/") {
      window.scrollTo(0, 0);
      document.getElementById("homeMenuText").style.borderBottom =
        "solid 2px red";
    } else {
      setTimeout(() => {
        document.getElementById("homeMenuText").style.borderBottom = "none";
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
          const menuItems = {
            "#about-us": "aboutUsMenuText",
            "#portfolio": "gamesMenuText",
            "#contact-us": "mediaMenuText",
          };
          if (menuItems[hash]) {
            document.getElementById(menuItems[hash]).style.borderBottom =
              "solid 2px red";
          }
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  const [, setVisibleSection] = useState("");

  useEffect(() => {
    const handleSectionChange = (id) => {
      console.log("Visible section is:", id);
      switch (id) {
        case "home":
          homeClick();
          break;
        case "about-us":
          underlineMenuAbout();
          break;
        case "portfolio":
          underlineMenuPortfolio();
          break;
        case "contact-us":
          underlineMenuContact();
          break;
        case "footerBackground":
          document.getElementById("aboutUsMenuText").style.borderBottom =
            "none";
          document.getElementById("mediaMenuText").style.borderBottom = "none";
          break;
        default:
          document.getElementById("homeMenuText").style.borderBottom =
            "solid 2px";
          document.getElementById("homeMenuText").style.borderColor = "red";
          document.getElementById("aboutUsMenuText").style.borderBottom =
            "none";
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.id;
            setVisibleSection(id);
            handleSectionChange(id);
          }
        });
      },
      { threshold: 0.555, root: document.body }
    );

    const sections = document.querySelectorAll(".section");
    sections.forEach((section) => observer.observe(section));

    return () => observer.disconnect();
  }, []);

  const [state] = useForm("mbjvqlka");
  if (state.succeeded) {
    return <p>Thanks for your submission!</p>;
  }
  return (
    <div className="container" style={{ overflowX: "hidden" }}>
      <Helmet>
        <title>THE RISE COLLECTION</title>
        <meta
          name="description"
          content="THE RISE COLLECTION is an internet-based software company. We produce and publish arcade style games on multiple platforms. Our games are increasingly difficult and designed to be replayed.."
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="flex-box-header header">
        <div className="headerText">
          <a href="/">
            <h2 className="headerTextCompany">THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="#portfolio" onClick={underlineMenuPortfolio}>
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <div className="section">
        <div id="home">
          <VideoOverlay />
        </div>
      </div>
      <div
        id="about-us"
        className="section"
        style={{ backgroundColor: "#0b0b0b", overflowX: "hidden" }}
      >
        <h2
          id="aboutUsHeader"
          style={{
            color: "white",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
            paddingBottom: "15px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          ABOUT US
        </h2>
        <div className="app-container">
          <p
            // id="about1"
            style={{
              color: "white",
              marginTop: "-150px",
              textAlign: "left",
              fontSize: "20px",
            }}
          >
            THE RISE COLLECTION is a software development studio. Founded in
            early 2015 initially under the name{" "}
            <span style={{ color: "red" }}>RISE STUDIOS</span>, RISE found some
            early success with a mobile game,{" "}
            <span>
              <a
                href="https://apps.apple.com/mm/app/keep-on-trucking/id1176379021"
                style={{ color: "red", textDecoration: 'none' }}
              >
                KEEP ON TRUCKING
              </a>
            </span>
            , and has since expanded into creating brands, assets and custom
            software solutions meant to entertain users and improve the world we
            live in.
            <br />
            <br />
          </p>
        </div>
        <div style={{ textAlign: "center", paddingTop: "5px" }}>
          <a
            href="/about-us"
            style={{
              color: "white",
              borderBottom: "solid 2px red",
              textDecoration: "none",
              fontFamily: "Roboto, sans-serif",
              fontSize: "16px",
              alignSelf: "center",
            }}
          >
            READ MORE
          </a>
        </div>
      </div>
      <div
        id="portfolio"
        className="section"
        style={{
          backgroundColor: "#0b0b0b",
          overflowX: "hidden",
        }}
      >
        <h2
          id="aboutUsHeader"
          style={{
            color: "white",
            marginTop: "195px",
            textAlign: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: "30px",
            paddingBottom: "25px",
          }}
        >
          FEATURED WORK
        </h2>
        <div className="gridContainer">
          {PortfolioList.filter(
            (value) =>
              query === "" ||
              value.title.toLowerCase().includes(query.toLowerCase())
          ).map((value, i) => (
            <div key={i}>
              <a href={value.serve} style={{ textDecoration: "none" }}>
                <Card
                  style={{
                    backgroundColor: "black",
                    border: "1px solid",
                    borderColor: "#282828",
                  }}
                  sx={{ minWidth: 345 }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
                      alt="Portfolio Images"
                      backgroundColor="black"
                    />
                    <CardContent style={{ backgroundColor: "black" }}>
                      <Typography gutterBottom variant="h5" component="div">
                        <a
                          style={{ color: "white", textDecoration: "none" }}
                          href={value.serve}
                        >
                          {value.title}
                        </a>
                      </Typography>
                      <Typography variant="subtitle1" color="red">
                        {value.category} {value.platform}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </a>
            </div>
          ))}
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "25px",
            paddingBottom: "100px",
          }}
        >
          <a
            id="viewMore"
            href="/portfolio"
            style={{
              color: "white",
              borderBottom: "solid 2px red",
              textDecoration: "none",
              fontFamily: "Roboto, sans-serif",
            }}
          >
            VIEW MORE
          </a>
        </div>
      </div>

      <div
        id="contact-us"
        className="section"
        style={{ backgroundColor: "#0b0b0b", overflowX: "hidden" }}
      >
        <h2
          id="contactUsHeader"
          style={{
            color: "white",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          CONTACT US
        </h2>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "20px",
            margin: "0 auto",
            paddingRight: "4px",
            paddingLeft: "4px",
          }}
        >
          Have a question? Want to learn more about what we develop? Send us a
          message!
        </p>
        <div className="form-container">
          <form
            className="centered-form"
            action="https://formspree.io/f/mbjvqlka"
            method="post"
          >
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="your@email.com"
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                style={{ resize: "none", width: "100%", height: "100px" }}
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div className="section" id="footerBackground">
        <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
