import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { useForm } from '@formspree/react';
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import { mediaMenu } from "./checkUnderline";
import { FaSquareXTwitter } from "react-icons/fa6";

const footerLogo = require("./test-img/logo.png");





const ContactUsPage = () => {

  const [state, handleSubmit] = useForm('mbjvqlka');

  if (state.succeeded) {
    return <p>Thanks for your submission!</p>;
  }


  return (
    <div className="creative-portfolio" style={{ overflowX: 'scroll', minHeight: '100vh', background: '#0B0B0B' }}>
      {/* Start Header Area  */}
      <meta
        name="viewport"
        content="width=100, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=0"
      />
      <div className="flex-box-header header">
        <div className="headerText">
          <a href="/">
            <h2>THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/">
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/about-us">
            <p id="aboutMenuText" >ABOUT US</p>
          </Link>
          <Link to="/#portfolio">
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>


          <Link to="/contact-us" onClick={mediaMenu}>
            <p id="mediaMenuText" style={{ borderBottom: 'solid 2px', borderColor: 'red' }}>CONTACT US</p>
          </Link>
        </div>
      </div>
      <h2 id="contactUsHeader" style={{ color: 'white', marginTop: '175px', textAlign: 'center', fontSize: '35px' }}>CONTACT US</h2>
      <p style={{ color: 'white', display: 'block', marginBlockStart: '1em', marginBlockEnd: '1em', marginInlineStart: '40px', marginInlineEnd: '40px', fontSize: '17px', textAlign: 'center' }}>Have a question? Want to learn more about what we develop? Reach out using the form below!</p>

      <div className="form-container">
        <form className="centered-form" action="https://formspree.io/f/mbjvqlka" method="post">
          <h2>Contact Us</h2>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" placeholder="Your Name" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" placeholder="Your Email" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" placeholder="Your Message"></textarea>
          </div>
          <button type="submit" onSubmit={handleSubmit}>Submit</button>
        </form>
      </div>






      <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
    </div>
  )
}

export default ContactUsPage
