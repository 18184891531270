import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
    homeClick,
    underlineMenuAbout,
    underlineMenuContact,
    underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";

const iconImage = require("./test-img/portfolio-2.jpg");
const appleImage = require("./test-img/nifty.png");
const screenShot1 = require("./test-img/RISE_ARCADE.PNG");
const footerLogo = require("./test-img/logo.png");


export function useTitle(title) {
    useEffect(() => {
        const prevTitle = window.document.title
        window.document.title = title
        return () => {
            window.document.title = prevTitle
            console.log(`window title: ${window.document.title}`)
        }
    })
}

const HelloWorld = () => {
    useTitle("RISE Arcade - RISE")

    return (
        <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
            <div className="flex-box-header header" id="detail">
                <div className="headerText">
                    <a href="/">
                    <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
                    </a>
                </div>
                <div className="menuItems">
                    <Link to="/" onClick={homeClick}>
                        <p id="homeMenuText">HOME</p>
                    </Link>
                    <Link to="/#about-us" onClick={underlineMenuAbout}>
                        <p id="aboutUsMenuText">ABOUT US</p>
                    </Link>
                    <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
                        <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
                    </Link>
                    <Link to="/#contact-us" onClick={underlineMenuContact}>
                        <p id="mediaMenuText">CONTACT US</p>
                    </Link>
                </div>
            </div>


            <div className="app-container">
                <div className="game-info">
                    <img src={iconImage} alt="Game Icon" className="game-icon" />
                    <div className="game-details">
                        <h2 style={{ fontSize: "18px" }}>RISE Arcade</h2>
                        <p style={{ fontSize: '16px', color: 'red', fontWeight: 'bold' }}>THE RISE COLLECTION</p>
                        <div className='tag-container'>
                            <div className='right-tag' style={{ marginRight: '5px', background: 'black', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px', width: '75px' }}>ASSET</div>
                            <div className='left-tag' style={{ background: '#3AE9C9', width: '130px', textAlign: 'center', height: '20px', color: 'white', borderRadius: '5px', paddingTop: '0.98px' }}>NIFTY ISLAND</div>
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>About the Asset</h2>
                    <p style={{ fontSize: '18px' }}>
                        RISE Arcade is a mini-game focused island on the Nifty Island Meta-Verse. Nifty Island is centered around user-generated content (UGC), allowing players to terraform landscapes, construct buildings, and decorate with foliage.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                        Players can build and decorate their own islands, complete quests, and play games with friends. Players can also use their non-fungible tokens (NFTs) as avatars in the game.
                    </p>
                    <p style={{ fontSize: '18px' }}>
                        Nifty Island is a product of a Texas-based company that offers a platform for communities to engage in immersive gaming experiences.
                    </p>
                    <hr />

                </div>
                <h2>Media</h2>

                <div className="screenshot-container-sideways">

                    <img src={screenShot1} alt='HELLO_WORLD_SCREENSHOT_1' />
    


                </div>
                <hr />
                <div style={{ marginBottom: '20px' }}>
                    <h2>Additional Information</h2>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Release Date: November 28, 2024</li>
                        <li>Developer: <a href="https://www.linkedin.com/company/therisecollection"
                            style={{ color: "red" }}>THE RISE COLLECTION</a></li>                    </ul>
                </div>
                <hr></hr>
                <h2>Download</h2>

                <div className="game-description">

                    <a href='https://niftyis.land/rwtrcsc/hello-world?ref=rwtrcsc'><img src={appleImage} style={{ width: '175px', marginLeft: '-10px' }} alt='HELLO_WORLD_PLAY' /></a>

                </div>
            </div>
            <br></br>
            <div style={{backgroundColor: 'black', }}>
        <br></br>
        <br></br>

        <div className="footer-content" style={{backgroundColor: 'black'}}>
        <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
        </div>
</div>
        </div>
    )
}

export default HelloWorld
