import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import ShootingStar from './ShootingStar';

const backgroundImage = require('./test-img/world.jpg')
const footerLogo = require("./test-img/logo.png");


export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const UnknownPage = () => {
  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#030303' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
          <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>

      <ShootingStar />
      <ShootingStar />
      <div className="app-container" style={{ backgroundColor: "#030303" }}>


        <div style={{ textAlign: 'center', height: '60vh' }}>

          <img src={backgroundImage} alt="rise_world" />
          <h2 style={{
            color: "white",
            textAlign: "center",
            fontSize: "30px",
          }}>404</h2>

        </div>




      </div>
      <ShootingStar />

      <ShootingStar />
      <br></br>
      <div style={{backgroundColor: 'black', }}>
        <br></br>
        <br></br>

        <div className="footer-content" style={{backgroundColor: 'black'}}>
        <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
        </div>
</div>
    </div>
  )
}

export default UnknownPage
