import React from 'react';
import './VideoOverlay.css'; // Import the CSS file
import backgroundVideoMP4 from "../src/script/test-img/backgroundVideo.mp4";
import backgroundVideoWEBM from "../src/script/test-img/backgroundVideo.webm";
import posterImage from "../src/script/test-img/poster2.jpg";
import { useNavigate } from 'react-router-dom';

const VideoOverlay = () => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/portfolio')
    }

  return (
    <div className="video-container">
           <video
            loop
            muted
            autoPlay
            playsInline
            controls={false}
            preload="metadata"
            id="video"
            onError={(e) => console.error("Video failed to load", e)}
            poster={posterImage}
          >
            <source />
            <source src={backgroundVideoWEBM} type="video/webm" />
            <source src={backgroundVideoMP4} type="video/mp4" />
          </video>
      <div className="overlay">
        <p className="textBig"><span style={{color: 'white'}}>INNOVATING</span> WITHOUT <span style={{color: 'white'}}>FORGETTING</span> TO BE <span style={{color: 'red'}}>CREATIVE</span></p>
        <p className="small-text">THE RISE COLLECTION builds <span style={{color: 'red'}}>BRANDS</span>, <span style={{color: 'red'}}>ASSETS</span> and custom <span style={{color: "red"}}>SOFTWARE</span> solutions focused on providing entertainment and improving the <span style={{color: 'red'}}>WORLD</span> we live in.</p>
        <button className="portfolio-button" onClick={handleClick}>PORTFOLIO</button>

      </div>
      
    </div>
  );
};
export default VideoOverlay;