
import React from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./script/checkUnderline.js";
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";

const footerLogo = require("./script/test-img/logo.png");


export function useTitle(title) {
  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const PrivacyPolicy = () => {
  useTitle("About Us - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#ffffff' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>


      <div className="app-container" >
        <div style={{ textAlign: 'center' }}>
          <h2 style={{
            fontSize: "30px", fontFamily: "Poppins, sans-serif",

          }}>ABOUT US</h2>




        </div>


        <p style={{
          fontSize: '25px', color: "red", fontWeight: 'bold', fontFamily: "Poppins, sans-serif",
        }}>DEAR USER,</p>

        <p style={{ fontSize: '20px' }}> At THE RISE COLLECTION, we’re not just a software company—we’re a team of passionate developers dedicated to crafting unique, arcade-style games for players around the world. Our games are designed to challenge, engage, and keep you coming back for more with increasingly difficult levels and endless replay value.
        </p>
        <p style={{
          fontSize: '25px', color: "red", fontWeight: 'bold', fontFamily: "Poppins, sans-serif",

        }}>A JOURNEY OF INNOVATION</p>
        <p style={{ fontSize: '20px' }}> Founded by Joshua, Mitch and Benjamin in 2014, RISE began as Rise Studios, publishing our first mobile game, Attack of the Circle (AOTC), built using the Game Salad engine. Inspired by the rapid growth of mobile gaming at the time, we set out with a clear vision: "Innovate without losing sight of creativity." Over the last 10 years, we've transformed that vision into a reality, creating games that reflect both our growth as developers and our commitment to pushing the boundaries of what's possible in gaming. </p>
        <p style={{
          fontSize: '25px', color: "red", fontWeight: 'bold', fontFamily: "Poppins, sans-serif",

        }}>WHAT WE DO</p>
        <p style={{ fontSize: '20px' }}>  We specialize in creating games that are both accessible and challenging, available on multiple platforms. At RISE our focus is not "locked" on games.. we enjoy every aspect of Software and will continue to grow in fields like:

          <uL>
            <li>
              Quality Assurance
            </li>
            <li>
              Tools & API Development
            </li>
            <li>
              Responsive Web Development
            </li>
            <li>
              Software Documentation
            </li>
            <li>
              Analytics
            </li>
          </uL>
        </p>
        <p style={{
          fontSize: '25px', color: "red", fontWeight: 'bold', fontFamily: "Poppins, sans-serif",

        }}>OUR COMMITMENT</p>
        <p style={{ fontSize: '20px' }}>At THE RISE COLLECTION, we're driven by a passion for software development and a deep love for gaming. As a team, we're commited to creating immersive expericences and continuously innovating to meet new requirments..</p>
        <p style={{ fontSize: '20px', }}>Thank you for supporting us on this exciting journey. We're thrilled to share our passion with you and look forward to bringing you even more exciting projects in the future.</p>
        <div
        id="contact-us"
        className="section"
        style={{ backgroundColor: "#FFFFFF", overflowX: "hidden", }}
      >
        <h2
          id="contactUsHeader"
          style={{
            color: "black",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          CONTACT US
        </h2>
        <p
          style={{
            color: "black",
           textAlign: 'center',
            fontSize: "20px",
           margin: "0 auto",
            paddingRight: "4px",
            paddingLeft: "4px",
          }}
        >
          If you're a brand, developer, or artist looking to collaborate.. or just a fan of our work we want to hear from you!
        </p>
        <div className="form-container">
          <form
            className="centered-form"
            action="https://formspree.io/f/mbjvqlka"
            method="post"
          >
            <div className="form-group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="your@email.com"
              />
            </div>
            <div className="form-group">
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                style={{ resize: "none", width: "100%", height: "100px" }}
              ></textarea>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      <div
        id="contact-us"
        className="section"
        style={{ backgroundColor: "#FFFFFF", overflowX: "hidden", }}
      >
        <h2
          id="contactUsHeader"
          style={{
            color: "black",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          ARTICLES
        </h2>
        <p
          style={{
            color: "black",
           textAlign: 'center',
            fontSize: "20px",
           margin: "0 auto",
            paddingRight: "4px",
            paddingLeft: "4px",
          }}
        >
          Explore articles written by us.. Or about us by other creators!
        </p>
        <br></br>
        <br></br>
        <br></br>
        <p
          style={{
            color: "black",
           textAlign: 'center',
            fontSize: "20px",
            paddingRight: "4px",
            paddingLeft: "4px",
            marginBottom: '-200px'
          }}
        >
          There is nothing just yet... checkout our Medium publications {" "}
          <span style={{ color: "red" }}><a style={{color:'red', }} href='https://medium.com/therisecollection'>here.</a></span>
        </p>
        
      </div>
      
      </div>
     
      <div style={{backgroundColor: 'black', }}>
        <br></br>
        <br></br>

        <div className="footer-content" style={{backgroundColor: 'black'}}>
        <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
        </div>
</div>
    </div>
  )
}

export default PrivacyPolicy
