import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PageScrollTop from "./PageScrollTop";
import AppTest from "./AppTest";
import PrivacyPolicy from "./script/PrivacyPolicy";
import ContactUsPage from "./script/ContactUsPage";
import AboutUsPage from "./AboutUsPage";
import BL1NKDetailPage from "./BL1NKDetailPage";
import BaaridgeDetailPage from "./BaaridgeDetailPage";
import KeepOnTruckingDetailPage from "./script/KeepOnTruckingDetailPage";
import Stack8DetailPage from "./script/Stack8DetailPage";
import RedLightCamDetailPage from "./redlightcam"; // Ensure the path is correct
import UnknownPage from "./script/UnknownPage";
import HelloWorld from "./script/HelloWorld";
import FullPortfolio from "./script/FullPortfolio";
import RiseKameHouse from "./script/risekamehouse";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter basename="/">
    <PageScrollTop>
      <Routes>
        <Route path="/" element={<AppTest />} />
        <Route path="/portfolio" element={<FullPortfolio/>} />
        <Route path="/portfolio/rise-kamehouse" element={<RiseKameHouse/>} />
        <Route path="/portfolio/rise-arcade" element={<HelloWorld/>} />
        <Route path="/about-us" element={<AboutUsPage/>} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/portfolio/redlightcam"
          element={<RedLightCamDetailPage />}
        />
        <Route path="/portfolio/stack-8" element={<Stack8DetailPage />} />
        <Route
          path="/portfolio/keep-on-trucking"
          element={<KeepOnTruckingDetailPage />}
        />
        <Route path="/portfolio/baaridge" element={<BaaridgeDetailPage />} />
        <Route path="/portfolio/bl1nk" element={<BL1NKDetailPage />} />
        <Route path="#about-us" element={<AboutUsPage />} />
        <Route path="#contact-us" element={<ContactUsPage />} />
        <Route path="*" element={<UnknownPage />} />
      </Routes>
    </PageScrollTop>
  </BrowserRouter>
);
