import React from "react";
import "./Footer.css";

function Footer() {
  return (
<div id="div_top_hypers">
    <ul id="ul_top_hypers">
    <li> <a href="" class="a_top_hypers">THE RISE COLLECTION © 2025   </a></li>

        <li> <a href="" class="a_top_hypers"> PRIVACY POLICY</a></li>
        <li><a href="" class="a_top_hypers"> | SOCIAL</a></li>
    </ul>
</div>
  );
}

export default Footer;