import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { FaFacebookSquare, FaYoutubeSquare, FaInstagramSquare } from "react-icons/fa";
import {
  homeClick,
  underlineMenuAbout,
  underlineMenuContact,
  underlineMenuPortfolio,
} from "./checkUnderline";
import '../../src/PreviewPage.scss'; // Import your SCSS file for styling
import { useEffect } from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
const footerLogo = require("./test-img/logo.png");

const PortfolioList = [
  {
    images: "15",
    category: "ASSET",
    title: "RISE Kame House",
    serve: "/portfolio/rise-kamehouse",
    platform: "",
  },
  {
    images: "2",
    category: "ASSET",
    title: "RISE Arcade",
    serve: "/portfolio/rise-arcade",
    platform: "",
  },
  {
    images: "10",
    category: "BRAND",
    title: "redlightcam",
    serve: "/portfolio/redlightcam",
    platform: "",
  },
  {
    images: "3",
    category: "MOBILE GAME",
    title: "Baaridge",
    serve: "/portfolio/baaridge",
    platform: "",
  },
  {
    images: "4",
    category: "MOBILE GAME",
    title: "BL1NK",
    serve: "/portfolio/bl1nk",
    platform: "",
  },
  {
    images: "1",
    category: "MOBILE GAME",
    title: "Keep On Trucking",
    serve: "/portfolio/keep-on-trucking",
    platform: "",
  },
  {
    images: "8",
    category: "MOBILE GAME",
    title: "Stack 8",
    serve: "/portfolio/stack-8",
    platform: "",
  },
];

export function useTitle(title) {



  useEffect(() => {
    const prevTitle = window.document.title
    window.document.title = title
    return () => {
      window.document.title = prevTitle
      console.log(`window title: ${window.document.title}`)
    }
  })
}

const FullPortfolio = () => {
  const [query, setQuery] = useState('')

  useTitle("Portfolio - RISE")

  return (
    <div className="creative-portfolio" style={{ minHeight: '100vh', background: '#0b0b0b' }}>
      <div className="flex-box-header header" id="detail">
        <div className="headerText">
          <a href="/">
            <h2 className='headerTextCompany' >THE RISE COLLECTION</h2>
          </a>
        </div>
        <div className="menuItems">
          <Link to="/" onClick={homeClick}>
            <p id="homeMenuText">HOME</p>
          </Link>
          <Link to="/#about-us" onClick={underlineMenuAbout}>
            <p id="aboutUsMenuText">ABOUT US</p>
          </Link>
          <Link to="/#portfolio" onClick={underlineMenuPortfolio}>
            <p style={{ borderBottom: 'solid 2px', borderColor: 'red' }} id="gamesMenuText">PORTFOLIO</p>
          </Link>
          <Link to="/#contact-us" onClick={underlineMenuContact}>
            <p id="mediaMenuText">CONTACT US</p>
          </Link>
        </div>
      </div>
      <div
        style={{ background: '#0b0b0b', overflowX: "hidden", }}
      >
        <h2
          id="contactUsHeader"
          style={{
            color: "white",
            marginTop: "25vh",
            textAlign: "center",
            fontSize: "30px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          PORTFOLIO
        </h2>
        <div>
        <p
          style={{
            color: "white",
           textAlign: 'center',
            fontSize: "20px",
           margin: "0 auto",
            paddingRight: "12.10px",
            paddingLeft: "12.10px",
          }}
        >
          THE RISE COLLECTION portfolio includes {""}<span style={{color: 'red'}}>BRANDS</span>, {""}<span style={{color: 'red'}}>ASSETS</span> and custom {""}<span style={{color: 'red'}}>SOFTWARE</span> solutions.
        </p>
        </div>
    
        <br></br>
    
      </div>

      <div className='gridContainer' style={{paddingTop: '50px', paddingBottom: '150px'}}>




{PortfolioList.filter((value) => {
  if (query === '') {
    return value
  } else if (value.title.toLowerCase().includes(query.toLowerCase())) {
    return value
  } 

}).map((value, i) => (
  
  <div >

    <div key={i}>
      <a href={value.serve} style={{textDecoration: 'none'}}>
        <Card style={{backgroundColor: 'black'}}sx={{ minWidth: 345, border: '1px solid', borderColor: '#282828'}}>

          <CardActionArea>
            <CardMedia
              component="img"
              image={`/assets/images/portfolio/portfolio-${value.images}.jpg`}
              alt="Portfolio Images"
              backgroundColor="black"
            />
            <CardContent style={{backgroundColor: 'black'}}>
                <Typography variant="body2" color="red">
                {value.category} {value.platform}
              </Typography>
              <Typography gutterBottom variant="h5" component="div" >
                <a style={{ color: 'white', textDecoration: "none" }} href={value.serve}>{value.title}</a>
              </Typography>
            
            </CardContent>
          </CardActionArea>
        </Card>

      </a>


    </div>

  </div>




))}

</div>
<div style={{backgroundColor: 'black', }}>
        <br></br>
        <br></br>

        <div className="footer-content" style={{backgroundColor: 'black'}}>
        <footer className="footer">
          <div className="footer-content" style={{ paddingBottom: "25px" }}>
            <div className="footer-social">
              <img src={footerLogo} style={{ width: "75px" }} />
              <br></br>
              <a
                    href="/privacy-policy"
                    style={{
                      textDecoration: "none",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: "15px",
                      color: "#626262",
                    }}
                  >
                    PRIVACY POLICY
                  </a> 
              <div>
                <br></br>
                
                <span>
                  {" "}
                 
  
                 <a href="https://www.youtube.com/channel/UCKbCkAn_nig1WaweOc7kl7Q" style={{ textDecoration: 'none', fontFamily: 'Roboto, sans-serif', fontSize: '15px', color: '#626262', paddingLeft: '5px' }}>  SOCIAL</a>
             

                </span>
              </div>

              <br></br>
              <br></br>
              <div style={{ marginTop: "-30px" }}></div>
            </div>
          </div>
        </footer>
        </div>
</div>
    
    </div>
  )
}

export default FullPortfolio
